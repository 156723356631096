/*eslint-disable*/
import { nodeName } from "jquery";
import React from "react";

// reactstrap components
import { Row, Container } from "reactstrap";

function Footer() {
  return (
    <footer style={{backgroundColor:"#41599E"}} className="footer footer-black footer-white">
      <Container>
        <Row>
          <nav className="footer-nav">
            <ul>
              <li>
                <a
                  href="https://www.sebsa.com.br"
                  target="_blank"
                  style={{fontSize:14, fontWeight:400, textTransform:"none", color:"#fff"}}
                >
                  Grupo SEB
                </a>
              </li>  
              <li>
                <a
                  href="https://sebsa.com.br/privacidade-cookies"
                  target="_blank"
                  style={{fontSize:14,fontWeight:400, textTransform:"none", color:"#fff"}}
                >
                  Área LGPD
                </a>
              </li> 
                         
            </ul>
          </nav>
          <div className="credits ml-auto">
            <span className="copyright" style={{fontWeight:400, fontSize:14, color:"#fff"}}>
              © {new Date().getFullYear()} Copyright. Grupo SEB.
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
