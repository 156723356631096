
import React from "react";
// nodejs library that concatenates strings
import classnames from "classnames";
import { Link, animateScroll as scroll } from "react-scroll";
import Logo from "../../assets/img/logoSEB_2.png";
// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

function IndexNavbar2() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 90 ||
        document.body.scrollTop > 89
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 90 ||
        document.body.scrollTop < 90
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar className={classnames("fixed-top", navbarColor)} expand="lg">
      <Container >
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"           
            title="Programa de Estágio - Grupo SEB"
          >
             <NavLink
                href="/"
                style={{textTransform:"none", fontSize:14, color:"#333", fontWeight:400, cursor:"pointer"}}
              >
                <img src={Logo} width="80" />
              </NavLink>
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>            
            <NavItem>
            <NavLink
                href="/#vemserseb"
                style={{textTransform:"none", fontWeight:400, fontSize:16, cursor:"pointer"}}
              >
                  #VEMSERSEB            
              </NavLink>
            </NavItem>
            <NavItem>
            <NavLink
                href="/#oprograma"
                style={{textTransform:"none", fontWeight:400, fontSize:16, cursor:"pointer"}}
              >
                  O Programa            
              </NavLink>
            </NavItem>
            <NavItem>
            <NavLink
                href="/#requisitos"
                style={{textTransform:"none", fontWeight:400, fontSize:16, cursor:"pointer"}}
              >
                  Requisitos            
              </NavLink>
            </NavItem>
            <NavItem>
            <NavLink
                href="/#vantagens"
                style={{textTransform:"none", fontWeight:400, fontSize:16, cursor:"pointer"}}
              >
                  Vantagens            
              </NavLink>
            </NavItem>
            <NavItem>
            <NavLink
                href="/#etapas"
                style={{textTransform:"none", fontWeight:400, fontSize:16, cursor:"pointer"}}
              >
                  Etapas            
              </NavLink>
            </NavItem>
            <NavItem>
            <NavLink
                href="/oportunidades"
                style={{textTransform:"none", fontWeight:400, fontSize:16, cursor:"pointer"}}
              >
                  Oportunidades            
              </NavLink>
            </NavItem>
            <NavItem>
            <Button
                className="btn-round"
                style={{backgroundColor:"#E4E140",color:"#41599E", display:"none", textTransform:"none", borderColor:"transparent", fontSize:16, fontWeight:600, }}
                href="https://www.vagas.com.br/v2303131/"
                target="_blank"
              >
                Inscreva-se!

              </Button>           
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar2;
