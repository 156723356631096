import React from "react";

// reactstrap components
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";


import "../../../node_modules/video-react/dist/video-react.css";





// core components

function IndexHeader2() {
  const [largeModal, setLargeModal] = React.useState(false);  
  return (
    <>
      <div
        className="page-header section-dark"
        style={{
          backgroundImage:
            "url(" + require("assets/img/bg_head2.jpg").default + ")",
        }}
      >

        
          <Container>
            <Row>       
              <Col sm style={{textAlign:"center", fontSize:16, color:"#fff"}}>
              
               <div style={{fontSize:40, fontWeight:600, marginTop:0}}>
                 Vagas & Oportunidades
               </div>
               <div>
               A gente já sabe. Com educação, podemos mudar o mundo. E é esse o nosso propósito: transformar pessoas para transformarem o mundo. 
               </div>           
              </Col>
            </Row>
            
          </Container>
        

      
  
      
      
      </div>
    </>
  );
}

export default IndexHeader2;
