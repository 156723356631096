import React from "react";
// plugin that creates slider
import Logos from "../../assets/img/logos.png";
import imgAcelera from "../../assets/img/img_acelera.png";
import imgRequisitos from "../../assets/img/img_requisitos.png";
import Img70 from "../../assets/img/70.png";
import Img20 from "../../assets/img/20.png";
import Img10 from "../../assets/img/10.png";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button, Card, CardImg, CardBody, CardTitle, CardText, FormGroup, Input, Modal
} from "reactstrap";

function Main() {
  const [scrollingLongContent, setScrollingLongContent] = React.useState(false);
  return (
    <>
      <div id="vemserseb">
      <Container>
                <div style={{textAlign:"center", marginTop:50, marginBottom:50}}>
        <img src={Logos} width="90%" />
        </div>
        </Container>
<hr style={{borderWidth:2}} />

        <Container>
          <div className="title" style={{fontSize:42, textAlign:"right"}}>
            <span style={{fontWeight:550, color:"#41599E"}}>#VEMSERSEB</span>
          </div>
          <div>          
            <Row>
           
              <Col sm>
              <img src={imgAcelera} width="100%" />;
              </Col>
              <Col sm>
              <div  style={{fontSize:22, fontWeight:400, lineHeight:2.2, textAlign:"right"}}>
              
<span style={{fontWeight:600, color:"#41599E"}}>
Por aqui, temos muito orgulho de fazer parte da transformação da educação há quase 60 anos, e sabemos que isso é apenas o começo. O futuro, acredite, vai ser incrível, e juntos podemos continuar construindo essa história! 
</span>


              </div>
              </Col>
            </Row>  
          </div>         
        </Container>
      </div>

   
      <div id="oprograma" style={{backgroundColor:"#41599E", paddingTop:10, paddingBottom:50,}}>
        <Container>
          <div className="title" style={{fontSize:18, textAlign:"center", marginBottom:50}}>
            <h2 style={{fontWeight:550, color:"#fff"}}>Quero saber tudo sobre o Programa!</h2>
          </div>
          <div>          
            <Row>
              <Col sm style={{textAlign:"center"}}>
                <span style={{color:"#fff", fontWeight:600, fontSize:18}}>
                Como vai <br />funcionar?
                </span>
                <hr />
                <span style={{color:"#fff", fontWeight:400, fontSize:14}}>
                O Programa de Estágio do Grupo SEB terá uma duração de até 24 meses.
                </span><br />&nbsp;
              </Col>
              <Col sm style={{textAlign:"center"}}>
                <span style={{color:"#fff", fontWeight:600, fontSize:18}}>
                Em quais áreas <br />poderei atuar?
                </span>
                <hr />
                <span style={{color:"#fff", fontWeight:400, fontSize:14}}>
                 Áreas de pedagogia das escolas do Grupo.<br />
                Áreas de tecnologia da Conexia, nossa editech que desenvolve soluções educacionais.
                </span><br />&nbsp;
              </Col>
              <Col sm style={{textAlign:"center"}}>
                <span style={{color:"#fff", fontWeight:600, fontSize:18}}>
                Qual o grande diferencial desse Programa?
                </span>
                <hr />
                <span style={{color:"#fff", fontWeight:400, fontSize:14}}>
                Você será protagonista da sua carreira e responsável pela construção da sua história com a gente.
E, para se desenvolver, terá liberdade de inovar para deixar sua marca na sua área de atuação e será sempre desafiado a ir além!  </span><br />&nbsp;
              </Col>
            </Row>  

            <div style={{textAlign:"center", paddingTop:30, color:"#fff", fontSize:18, fontWeight:600}}>
            Venha com a gente transformar o mundo por meio da Educação!
            </div>
            
          </div>         
        </Container>
      </div>

      <br /><br />
<div id="requisitos">
  <Container>
    
    <div>          
      <Row>
        <Col sm>&nbsp;<br /><br />
        <img src={imgRequisitos} width="100%" />
        </Col>
        <Col sm>
        <div className="title" style={{fontSize:42, textAlign:"right"}}>
      <span style={{fontWeight:550, color:"#41599E"}}>E quais são os Requisitos Gerais para participar?</span>
    </div>
        <div  style={{fontSize:14, fontWeight:400, textAlign:"right"}}>
        Você precisa estar com a matrícula ativa em um curso de graduação.<br /><br />
E, claro, estar a fim de transformar o mundo por meio da educação.

<br /><br />

        <i>* Os requisitos específicos estão descritos nas vagas abaixo.</i><br />&nbsp;<br /><br />
        </div>
        
        </Col>
      </Row>  
    </div>         
  </Container>
</div>


<div id="vantagens"  style={{backgroundColor:"#E4E140", paddingTop:10, paddingBottom:50}}>
        <Container>
          <div className="title" style={{fontSize:42, textAlign:"center", color:"#41599E"}}>
            <span style={{fontWeight:550, color:"#41599E"}}>Lembra que falamos que são muitas as vantagens de vir pra cá?

</span>
          </div>
          <div>          
            <Row>
              <Col>
              <div  style={{fontSize:20, fontWeight:400, color:"#41599E", textAlign:"center"}}>
              Não era só modo de falar, não! Confira quantas oportunidades de crescimento: 
              </div>
              </Col>
            </Row>  

            <Row style={{marginTop:20}}>         
              <Col sm style={{textAlign:"center"}}>
              <Card style={{height:220}}>                 
                    <CardBody>
                    <i className="nc-icon nc-planet"  style={{fontSize:50, color:"#41599E"}}  /><br />&nbsp;
                        <CardText style={{fontWeight: 400}}>Atuar em um grupo brasileiro de educação presente em mais de 30 países do mundo.</CardText>
                    </CardBody>
                </Card>
              </Col>
              <Col sm style={{textAlign:"center"}}>
              <Card style={{height:220}}>
                    <CardBody>
                    <i className="nc-icon nc-bulb-63" style={{fontSize:50, color:"#41599E"}} /><br />&nbsp;
                        <CardText style={{fontWeight: 400}}>Fazer parte de um ecossistema de educação que mais investe em inovação.</CardText>
                    </CardBody>
                </Card>
              </Col>
              <Col sm style={{textAlign:"center"}}>
              <Card style={{height:220}}>
                    <CardBody>
                    <i className="nc-icon nc-support-17" style={{fontSize:50, color:"#41599E"}} /><br />&nbsp;
                        <CardText style={{fontWeight: 400}}>Aprender na prática, todos os dias, do lado de profissionais incríveis.</CardText>
                    </CardBody>
                </Card>
              </Col>
              <Col sm style={{textAlign:"center"}}>
              <Card style={{height:220}}>
                    <CardBody>
                    <i className="nc-icon nc-spaceship" style={{fontSize:50, color:"#41599E"}} /><br />&nbsp;
                        <CardText style={{fontWeight: 400}}>Ter a oportunidade de crescer dentro do grupo.</CardText>
                    </CardBody>
                </Card>
              </Col>
            </Row>  

          </div>         
        </Container>
      </div>

  
<div id="vantagens" style={{backgroundColor:"#41599E", paddingTop:20, paddingBottom:50,}}>
        <Container>
          <div className="title" style={{fontSize:42, textAlign:"center"}}>
            <span style={{fontWeight:550, color:"#E4E140"}}>E até vai rolar uma Trilha específica para o seu desenvolvimento, dividida assim: 
</span>
          </div>
          <div>          
            <Row>
            <Col sm style={{textAlign:"center"}}>
              &nbsp;<br />
              <img src={Img70} width="50%" />
              <div style={{fontSize:20, fontWeight:600, color:"#E4E140", textAlign:"center"}}>
              &nbsp;<br />70% on-the-job
              </div>
              <div style={{fontSize:14, fontWeight:400, color:"#fff", textAlign:"center", marginTop:20}}>
              Isso significa que a maior parte do seu aprendizado será na prática, ou seja, aqui você aprende realizando!
              </div>              
              </Col>
              <Col sm style={{textAlign:"center"}}>
              &nbsp;<br />
              <img src={Img20} width="50%" />
              <div style={{fontSize:20, fontWeight:600, color:"#E4E140", textAlign:"center"}}>
              &nbsp;<br />20% Aprendizado com os outros
              </div>
              <div style={{fontSize:14, fontWeight:400, color:"#fff", textAlign:"center", marginTop:20}}>
              A gente garante que aqui tem os melhores profissionais para você se inspirar e trocar experiências diárias.

              </div>              
              </Col>
              <Col sm style={{textAlign:"center"}}>
              &nbsp;<br />
              <img src={Img10} width="50%" />
              <div style={{fontSize:20, fontWeight:600, color:"#E4E140", textAlign:"center"}}>
              &nbsp;<br />10% Aprendizado com cursos e treinamentos formais
              </div>
              <div style={{fontSize:14, fontWeight:400, color:"#fff", textAlign:"center", marginTop:20}}>
              A melhor parte de estar dentro do maior Grupo de Educação do Brasil é que você vai ter acesso a treinamentos com quem mais entende do assunto! 


              </div>              
              </Col>
            </Row>  
          </div>         
        </Container>
      </div>


      <br />





<div id="etapas"  style={{paddingTop:10, paddingBottom:30}}>
  <Container>
    <div className="title" style={{fontSize:42, textAlign:"center"}}>
      <span style={{fontWeight:550, color:"#41599E"}}>Bora conhecer as etapas para você vir pra cá?
</span>
    </div><br />
    <div>          
      <Row>
        <Col sm>
          <div style={{textAlign:"center", fontSize:20, fontWeight:600, paddingBottom:0,}}>
          <i className="nc-icon nc-single-copy-04" style={{fontSize:50, color:"#41599E"}} />
          </div>
          <div style={{textAlign:"center", fontSize:20, fontWeight:600, paddingBottom:0, color:"#41599E"}}>
          Passo 1
          </div>   
          <hr />     
          <div style={{textAlign:"center", fontSize:18, fontWeight:600, paddingBottom:10,}}>
          Inscrições
          </div>  
          <div style={{textAlign:"center", fontSize:16, fontWeight:400, paddingBottom:0,}}>
          22/10/2021
          </div>
        </Col>

        <Col sm>
          <div style={{textAlign:"center", fontSize:20, fontWeight:600, paddingBottom:0, }}>
          <i className="nc-icon nc-align-center" style={{fontSize:50, color:"#41599E"}} />
          </div>
          <div style={{textAlign:"center", fontSize:20, fontWeight:600, paddingBottom:0, color:"#41599E"}}>
          Passo 2
          </div>   
          <hr />     
          <div style={{textAlign:"center", fontSize:18, fontWeight:600, paddingBottom:10, }}>
          Testes
          </div>  
          <div style={{textAlign:"center", fontSize:16, fontWeight:400, paddingBottom:0, }}>
          Para ver se você tem tudo a ver com o Grupo e com a área, você passará por alguns testes de fit cultural e técnicos.
          </div>
        </Col>

        <Col sm>
          <div style={{textAlign:"center", fontSize:20, fontWeight:600, paddingBottom:0, }}>
          <i className="nc-icon nc-bulb-63" style={{fontSize:50, color:"#41599E"}} />
          </div>
          <div style={{textAlign:"center", fontSize:20, fontWeight:600, paddingBottom:0, color:"#41599E"}}>
          Passo 3
          </div>   
          <hr />     
          <div style={{textAlign:"center", fontSize:18, fontWeight:600, paddingBottom:10, }}>
          Conversa com a área de Gente e Gestão
          </div>  
          <div style={{textAlign:"center", fontSize:16, fontWeight:400, paddingBottom:0, }}>
          O pessoal do RH quer muito te conhecer melhor e dar todos os detalhes do programa.

          </div>
        </Col>

        <Col sm>
          <div style={{textAlign:"center", fontSize:20, fontWeight:600, paddingBottom:0, }}>
          <i className="nc-icon nc-chat-33" style={{fontSize:50, color:"#41599E"}} />
          </div>
          <div style={{textAlign:"center", fontSize:20, fontWeight:600, paddingBottom:0, color:"#41599E"}}>
          Passo 4
          </div>   
          <hr />     
          <div style={{textAlign:"center", fontSize:18, fontWeight:600, paddingBottom:10, }}>
          Conversa com o Líder
          </div>  
          <div style={{textAlign:"center", fontSize:16, fontWeight:400, paddingBottom:0, }}>
          E nem precisamos falar sobre o quanto os Líderes da área também querem te conhecer, né? Finalmente chegou esse momento!
          </div>
        </Col>

        <Col sm>
          <div style={{textAlign:"center", fontSize:20, fontWeight:600, paddingBottom:0, }}>
          <i className="nc-icon nc-chat-33" style={{fontSize:50, color:"#41599E"}} />
          </div>
          <div style={{textAlign:"center", fontSize:20, fontWeight:600, paddingBottom:0, color:"#41599E"}}>
          Passo 5
          </div>   
          <hr />     
          <div style={{textAlign:"center", fontSize:18, fontWeight:600, paddingBottom:10, }}>
          Admissão
          </div>  
          <div style={{textAlign:"center", fontSize:16, fontWeight:400, paddingBottom:0, }}>
          Agora só falta entregar a documentação, a chegar ao treinamento de integração e correr pro abraço!
          </div>
        </Col>

       
      </Row>

    </div>         
  </Container>
</div>
<div id="oportunidades"  style={{backgroundImage:
            "url(" + require("assets/img/bg_head3.jpg").default + ")", paddingTop:10, paddingBottom:0}}>
  <Container>
    <div className="title" style={{fontSize:42, color:"#fff", textAlign:"center", marginBottom:30}}>
      <span style={{fontWeight:550}}>Conheça nossas oportunidades e inscreva-se!</span>
    </div>
    <div>          
      <Row>
        <Col sm>
       
         <div style={{textAlign:"left",}}>
                  <Card>
                      <CardBody>              
                      <i className="nc-icon nc-laptop" style={{fontSize:40, color:"#41599E"}} /><br />
                          <span style={{fontWeight:600, fontSize:32, color:"#41599E"}}>Vaga Tech</span><br />
                          <span style={{fontWeight:600, fontSize:18, paddingTop:0}}>Conexia Educação</span><br />&nbsp; 
                          <div style={{margin:0, textAlign:"right", width:"100%", backgroundColor:"#E4E140", borderColor:"transparent", borderRadius:6, padding:12,}}>
                          <a href="/oportunidades#vaga1" style={{textTransform:"none",  color:"#41599E", fontSize:16, fontWeight:600,}}>
                          vaga encerrada<i className="nc-icon nc-minimal-right" style={{fontSize:10, color:"#41599E", marginLeft:20, fontWeight:600, verticalAlign:"middle"}} />
                            </a>
                            </div>
                            
                      </CardBody>
                  </Card>        
                  </div>
                  
                       
        </Col>

        <Col sm>
       
         <div style={{textAlign:"left",}}>
                  <Card>
                      <CardBody>              
                      <i className="nc-icon nc-tablet-2" style={{fontSize:40, color:"#41599E"}} /><br />
                          <span style={{fontWeight:600, fontSize:32, color:"#41599E"}}>Vaga Tech</span><br />
                          <span style={{fontWeight:600, fontSize:18, paddingTop:0}}>Grupo SEB Digital</span><br />&nbsp; 
                          <div style={{margin:0, textAlign:"right", width:"100%", backgroundColor:"#E4E140", borderColor:"transparent", borderRadius:6, padding:12,}}>
                          <a href="/oportunidades#vaga3" style={{textTransform:"none",  color:"#41599E", fontSize:16, fontWeight:600,}}>
                          vaga encerrada <i className="nc-icon nc-minimal-right" style={{fontSize:10, color:"#41599E", marginLeft:20, fontWeight:600, verticalAlign:"middle"}} />
                            </a>
                            </div>
                            
                      </CardBody>
                  </Card>        
                  </div>
                  
                       
        </Col>

        <Col sm>
        <Card>
                      <CardBody>  
        <i className="nc-icon nc-hat-3" style={{fontSize:40, color:"#41599E"}} /><br />
                          <span style={{fontWeight:600, fontSize:32, color:"#41599E"}}>Vaga Pedagógica</span><br />
                          <span style={{fontWeight:600, fontSize:18, paddingTop:0}}>Escola Bilíngue Pueri Domus</span><br />&nbsp; 
                          <div style={{margin:0, textAlign:"right", width:"100%", backgroundColor:"#E4E140", borderColor:"transparent", borderRadius:6, padding:12,}}>
                          <a href="/oportunidades#vaga2" style={{textTransform:"none",  color:"#41599E", fontSize:16, fontWeight:600,}}>
                          vaga encerrada <i className="nc-icon nc-minimal-right" style={{fontSize:10, color:"#41599E", marginLeft:20, fontWeight:600, verticalAlign:"middle"}} />
                            </a>
                            </div>
                            </CardBody>
                  </Card>  
        </Col>

      
      </Row> 

     
<br /> &nbsp;

    </div>         
  </Container>
</div>


    </>
  );
}

export default Main;
