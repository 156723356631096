import React from "react";
// plugin that creates slider
import Conexia from "../../assets/img/Conexia.jpg";
import Pueri from "../../assets/img/Pueri.jpg";
import SEB from "../../assets/img/SEB.jpg";



import { Link, animateScroll as scroll } from "react-scroll";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button, Card, CardImg, CardBody, CardTitle, CardText
} from "reactstrap";

function Main() {
 
  return (
    <>
      <div style={{marginTop:-170, marginBottom:50}}>

        <Container>
      
            <Row style={{marginTop:0}}>
              <Col sm>
                  <div style={{textAlign:"center"}}>
                  <Card>
                      <CardBody> 
                          <i className="nc-icon nc-laptop" style={{fontSize:40, color:"#41599E"}} /><br />
                          <span style={{fontWeight:600, fontSize:32, color:"#41599E"}}>Vaga Tech</span><br />
                          <span style={{fontWeight:600, fontSize:18, paddingTop:0}}>Conexia Educação</span><br />&nbsp; 
                          <div style={{margin:0, textAlign:"center", width:"100%", backgroundColor:"#E4E140", borderColor:"transparent", borderRadius:6, padding:12,}}>
                          <Link to="vaga1" spy={true} smooth={true} offset={-130} duration={1000} style={{textTransform:"none",  color:"#41599E", cursor:"pointer", fontSize:16, fontWeight:600,}}>
                          vaga encerrada<i className="nc-icon nc-minimal-down" style={{fontSize:10, color:"#41599E", marginLeft:20, fontWeight:600, verticalAlign:"middle"}} />
                          </Link>
                          </div>
                      </CardBody>
                  </Card>        
                  </div>       
                </Col>

                <Col sm>
                  <div style={{textAlign:"center"}}>
                  <Card>
                      <CardBody> 
                          <i className="nc-icon nc-tablet-2" style={{fontSize:40, color:"#41599E"}} /><br />
                          <span style={{fontWeight:600, fontSize:32, color:"#41599E"}}>Vaga Tech</span><br />
                          <span style={{fontWeight:600, fontSize:18, paddingTop:0}}>Grupo SEB Digital</span><br />&nbsp; 
                          <div style={{margin:0, textAlign:"center", width:"100%", backgroundColor:"#E4E140", borderColor:"transparent", borderRadius:6, padding:12,}}>
                          <Link to="vaga3" spy={true} smooth={true} offset={-130} duration={1000} style={{textTransform:"none",  color:"#41599E", cursor:"pointer", fontSize:16, fontWeight:600,}}>
                          vaga encerrada<i className="nc-icon nc-minimal-down" style={{fontSize:10, color:"#41599E", marginLeft:20, fontWeight:600, verticalAlign:"middle"}} />
                          </Link>
                          </div>
                      </CardBody>
                  </Card>        
                  </div>       
                </Col>

                <Col sm>
                <div style={{textAlign:"center"}}>
                <Card>
                  <CardBody>              
                   
                            <i className="nc-icon nc-hat-3" style={{fontSize:40, color:"#41599E"}} /><br />
                          <span style={{fontWeight:600, fontSize:32, color:"#41599E"}}>Vaga Pedagógica</span><br />
                          <span style={{fontWeight:600, fontSize:18, paddingTop:0}}>Escola Bilíngue Pueri Domus</span><br />&nbsp; 
                          <div style={{margin:0, textAlign:"center", width:"100%", backgroundColor:"#E4E140", borderColor:"transparent", borderRadius:6, padding:12,}}>
                          <Link to="vaga2" spy={true} smooth={true} offset={-130} duration={1000} style={{textTransform:"none",  color:"#41599E", cursor:"pointer", fontSize:16, fontWeight:600,}}>
                          vaga encerrada <i className="nc-icon nc-minimal-down" style={{fontSize:10, color:"#41599E", marginLeft:20, fontWeight:600, verticalAlign:"middle"}} />
                            </Link>
                            </div>        
                      </CardBody>
                  </Card>       
                  </div> 
                </Col>
              </Row>

           
              
          </Container>

          <hr style={{borderWidth:3}} />

      <Container id="vaga1">
        
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#41599E", marginBottom:10}}>
        Vaga Tech
        </div>
        <img src={Conexia} width="100%" />
        <hr style={{borderWidth:4}} />
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Quem somos? 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Já pensou em começar a sua carreira em uma das empresas de soluções educacionais mais inovadoras do país?<br /><br />

Prazer! Nós somos a Conexia Educação e fazemos parte do Grupo SEB, o maior grupo de Educação Básica do Brasil!<br /><br />

Nascemos com um DNA digital, uma visão inovadora e uma cultura ágil, com um único objetivo: desenvolver, agora, a educação do futuro.<br /><br />

Aqui, a tecnologia é a chave para potencializar a aprendizagem e mudar a vida de mais de 200 mil alunos, em mais de 400 escolas em todo o Brasil.<br /><br />

Vamos juntos transformar a educação!

 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Conheça mais sobre a vaga 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Você ama tecnologia, é uma pessoa criativa, ágil, comunicativa, cheia de vontade de aprender e adora contribuir em processos inovadores? Essa vaga é para você!<br /><br />

Durante o estágio, você vai atuar na área escolhida (Dev, Devops ou Dados) e dar um up em sua carreira, com experiência no desenvolvimento das nossas soluções educacionais!<br /><br /> 

E tem mais...<br /><br />
•	Imersão na cultura ágil<br />
•	Atuação ao lado de grandes experts em tecnologia<br />
•	Home office<br />
•	Transformação da educação, com o propósito de levar as melhores soluções para estudantes de todo o mundo.<br /><br />

Deu match? Então venha para o nosso time, #VemSerSEB!


 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        O que se espera do candidato
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Ter disponibilidade de 6 horas diárias, manhã ou tarde (totalizando 30 horas semanais), estar efetivamente matriculado (entre primeiro e penúltimo ano) e cursando tecnólogo ou graduação em um destes cursos:<br /><br />

• Ciência da Computação<br />
• Engenharia da Computação

 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Alguns requisitos
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        •	Criatividade e Inovação<br />
        •	Boa comunicação<br />
        •	Capacidade de adaptação<br />
        •	Agilidade no aprendizado<br />
        •	Vontade de aprender<br />
        •	Trabalho em equipe

 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Carga horária
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Ter disponibilidade de 6 horas diárias, manhã ou tarde. (totalizando 30 horas semanais). 
        </div>

        

        
        <div style={{textAlign:"right", display:"none"}}>
        <Button
                className="btn-round"
                style={{backgroundColor:"#E4E140", color:"#41599E", textTransform:"none", borderColor:"transparent", fontSize:16, fontWeight:600, }}
                href="https://www.vagas.com.br/v2303131/"
                target="_blank"
              >
                Inscreva-se!

              </Button>
              </div>
      </Container>

      <hr style={{borderWidth:3,}} />


      <Container id="vaga3">
        
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#41599E", marginBottom:10}}>
        Vaga Tech
        </div>
        <img src={SEB} width="100%" />
        <hr style={{borderWidth:4}} />
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Quem somos? 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Prazer! Somos o Grupo SEB! E há 50 anos somos agentes de transformação na educação com uma proposta disruptiva que impacta a vida de milhares de alunos ao redor do mundo. Nascemos no Brasil e, hoje, atuamos em mais de 20 países por meio da rede das escolas bilíngues canadenses Maple Bear, em mais de 1.000 polos de EAD, e fazemos a gestão de escolas renomadas como Concept e Pueri Domus, além da Conexia Educação, a empresa de soluções educacionais que mais investe em tecnologia e inovação com mais de 200 mil alunos em menos de 3 anos. Nosso propósito é transformar pessoas por meio da educação, porque acreditamos que pessoas curiosas, com senso crítico, criatividade e autonomia, ancoradas em valores humanos e na aprendizagem contínua, são capazes de transformar o mundo. Venha saber mais sobre como vamos transformar pessoas e o mundo!
 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Conheça mais sobre a vaga 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Durante o estágio, você vai atuar dentro da área de Digital desenvolvendo soluções voltadas para transformação digital e melhoria dos processos na companhia. Além disso, vai viver o dia a dia imerso em cultura ágil e aplicando tecnologias de ponta em ambientes na nuvem.<br /><br />
        
Se você ama tecnologia, ama dar ideias e criar soluções, quer transformar o mundo por meio da educação, e fazer tudo isso se desenvolvendo, venha para o nosso time, #VemSerSEB!


 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        O que se espera do candidato
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Estar efetivamente matriculado (entre primeiro e penúltimo ano) e cursando tecnólogo ou graduação em um destes cursos:<br /><br />
        •	 Ciência da Computação <br />
        •	Engenharia da Computação<br />
        •	Sistema de Informação<br />
        •	Análise e Desenvolvimento de Sistemas<br />
        •	Áreas relacionadas à Tecnologia da Informação<br /><br />
 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Alguns requisitos
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        •	Criatividade e Inovação<br />
        •	Boa comunicação<br />
        •	Capacidade de adaptação<br />
        •	Agilidade no aprendizado<br />
        •	Vontade de aprender<br />
        •	Trabalho em equipe

 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Carga horária
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        6 horas diárias, manhã ou tarde. (totalizando 30 horas semanais).
        </div>

        

        
        <div style={{textAlign:"right", display:"none"}}>
        <Button
                className="btn-round"
                style={{backgroundColor:"#E4E140", color:"#41599E", textTransform:"none", borderColor:"transparent", fontSize:16, fontWeight:600, }}
                href="https://www.vagas.com.br/v2303131/"
                target="_blank"
              >
                Inscreva-se!

              </Button>
              </div>
      </Container>

      <hr style={{borderWidth:3,}} />

      <Container id="vaga2">       
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#41599E", marginBottom:10}}>
        Vaga Pedagógica
        </div>
        <img src={Pueri} width="100%" />
        <hr style={{borderWidth:4}} />
        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Quem somos? 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        A Escola Bilíngue Pueri Domus tem 55 anos de educação inovadora, é referência e excelência em educação, tem com seu aluno o compromisso na construção do conhecimento e de valores éticos, tornando-o cidadão do mundo, capaz de sonhar, criar e realizar.
 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Conheça mais sobre a vaga 
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Como estagiário você vai acompanhar a adaptação, o comportamento e o desenvolvimento de aprendizagem dos alunos e auxiliar o professor nas atividades em grupo, além de demais rotinas de sala de aula.
 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        O que se espera do candidato
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Atuação nos segmentos Ensino Fundamental  II e Ensino Médio:<br /><br />

        Estar cursando a partir do segundo semestre: Letras, Artes, Biologia, Educação Física, Física, Geografia, História, Letras, Matemática ou Química + Inglês fluente.<br /><br />

        Atuação nos segmentos Ensino Infantil e Fundamental  I:<br /><br />

        Estar cursando a partir do segundo semestre: Pedagogia ou Letras + Inglês fluente.
 
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Horário de trabalho
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Ter disponibilidade de 7 horas diárias, manhã ou tarde. Com 1 hora de intervalo (totalizando 30 horas semanais).  
        </div>

        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Local de trabalho
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        Presencial Escola Pueri Domus - São Paulo -SP (em uma de nossas Unidades: Perdizes, Itaim, Verbo Divino e Aclimação). 
        </div>


        <div style={{textAlign:"left", fontSize:22, fontWeight:600, color:"#333", marginBottom:20, marginTop:20}}>
        Nosso Diferencial
        </div>

        <div style={{textAlign:"left", fontSize:16, fontWeight:400, color:"#333"}}>
        <strong>Jornada de Aprendizagem</strong><br /><br />

        •	Todos os estagiários passam por uma formação sobre a prática educacional.<br />
        •	Acompanhamento direto da coordenação pedagógica.<br />
        •	Alguns estagiários serão eleitos a Residência Pedagógica.<br /><br />

        <strong>Residência Pedagógica</strong><br /><br />

        •	Formação e desenvolvimento de carreira.<br />
        •	Complementação teórico / metodológico. <br/>
        •	Troca de experiências e vivências. <br />
        •	Estudos de casos - questões foco.<br /><br />

        <strong>Residência Pedagógica Eixos de Aprendizagem</strong><br /><br />

        •	Aspectos Socioemocionais.<br />
        •	Fundamentação Pedagógica. <br/>
        •	Documentos. <br />
        •	Eficiência Operacional.<br />
        •	Mindset de Inovação.<br />
        •	Comunicação e Proficiência

        </div>


        <div style={{textAlign:"right", display:"none"}}>
        <Button
                className="btn-round"
                style={{backgroundColor:"#E4E140", color:"#41599E", textTransform:"none", borderColor:"transparent", fontSize:16, fontWeight:600, }}
                href="http://vagas.com.br/v2357788"
                target="_blank"
              >
                Inscreva-se!

              </Button>
              </div>
      </Container>

      
</div>


    </>
  );
}

export default Main;
