import React, { useState } from "react";
import Cookies from 'js-cookie';
// reactstrap components
import { Container, Row, Col, Modal, Button } from "reactstrap";




// core components

function IndexHeader() {
 
  const [show, setShow] = useState(true);


  const clickCLoseButton = () => {
    Cookies.set('closed', true, { expires: 7})
    setShow((s) => !s)
  };


  return (
    <>
      <div
        className="page-header section-dark"
        style={{
          backgroundImage:
            "url(" + require("assets/img/bg_head_new.jpg").default + ")",
        }}
      >

        <div>
          <Container>
            <Row>  
              <Col sm style={{textAlign:"left", fontSize:18, color:"#fff"}}>
               <div style={{fontSize:30, fontWeight:500}}>
                 programa de
               </div>
               <div style={{fontSize:90, fontWeight:600, marginTop:-30}}>
                 estágio
               </div>
               <div>
               Já pensou em começar sua carreira dentro de um grupo de educação global com infinitas possibilidades de crescimento e aprendizado, em um ambiente inovador? <br /><br />
<span style={{fontWeight: 600}}>
Bateu aquela vontade? Se você é estudante universitário e está a fim de transformar o mundo por meio da educação, venha com a gente!</span>

               </div>
              
              </Col>
              <Col></Col>
            </Row>
          </Container>
        
    
          </div>

          <h6 className="category category-absolute">
          © {new Date().getFullYear()}. Grupo SEB
        </h6>
      
      </div>

      
      <div style={{ display: show ? "block" : "none" }}>
      <div style={{position:"absolute", bottom:380, right:380, zIndex:9999999999999}}>
        <div style={{position:"fixed", lineHeight:2, fontSize:12, padding:20, borderRadius:14, marginRight:20, backgroundColor:"#4260A0", color:"#fff"}}>

        O site do Grupo SEB usa “Cookies Operacionais de Sessão ou Persistentes” para o funcionamento do sistema, que podem armazenar seus dados pessoais indiretamente. Conforme detalhado nas <a href="https://sebsa.com.br/privacidade-cookies" style={{color:"#fff", fontWeight:600}} target="_blank">“Políticas de Cookies e Política de Privacidade”</a> e de acordo com o Art. 5 da LGPD (Lei 13.709/2018) solicitamos seu consentimento para prosseguir. Sua rejeição pode implicar no não funcionamento das tecnologias do site. Você aceita os Cookies de Sessão em seu navegador?
       
       <Row>
  
        <Col sm>
        <div style={{textAlign:"right", marginTop:20, marginBottom:10}}>
        <Button
              className="btn-round js-close"
              style={{color:"#fff", borderColor:"#fff", fontSize:12, fontWeight:400, textTransform:"none"}}
              onClick={clickCLoseButton}
              outline
            >
              Sim, eu aceito.
            </Button>&nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              className="btn-round"
              style={{color:"#fff", borderColor:"#fff", fontSize:12, fontWeight:400, textTransform:"none"}}
              onClick={clickCLoseButton}
              outline
            >
              Não, eu rejeito.
            </Button>
          </div>
        </Col>
        </Row>
        
        </div>
      </div>
      </div>

    </>
  );
}

export default IndexHeader;
